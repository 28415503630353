<template>
  <div class="container" v-if="step === 2">
    <div class="lucky_list3 clearfix">
      <div class="lpl_userInfo" style="width: auto;height: auto; padding: 10px;" v-for="(item, index) in nonePrizeUsers"
        :key="index">
        <img class="lpl_userImage" :style="{ width: imgWidth, height: imgHeight }"
          :src="item.avatar || 'https://thirdwx.qlogo.cn/mmopen/vi_32/POgEwh4mIHO4nibH0KlMECNjjGxQUq24ZEaGT4poC6icRiccVGKSyXwibcPq4BWmiaIGuG1icwxaQX6grC9VemZoJ8rg/132'"
          style="">
        <!-- <p class="lpl_userName" style="">{{ item.name }}</p> -->
      </div>
    </div>
  </div>
  <div class="mask"></div>
  <div id="stop" ref="stop" class="btn_circle" v-if="step === 2" @click="stop">停止</div>
  <div class="lucky_title">{{ pageData.title }}</div>
  <div class="lucky_list clearfix" v-if="step === 1">
    <div class="left lucky_prize">
      <div class="lucky_prize_box">
        <button class="lucky_prize_left  lucky_prize_direction" :class="leftActive" @click="changePrize(-1)"> &lt;
        </button>
        <div data-default="1" class="lucky_prize_picture">
          <img class="lucky_prize_show" :class="item.luckId === prize.luckId ? '' : 'none'" :src="item.goodsPic"
            :alt="item.goodsName" v-for="(item, index) in prizeList" :key="index" />
        </div>
        <button class="lucky_prize_right lucky_prize_direction" :class="rightActive" @click="changePrize(1)">></button>
      </div>
      <div class="lucky_prize_title">{{ prize.luckName }} </div>
      <div class="lucky_prize_title">{{ prize.goodsName }}</div>
      <div class="lucky_setting">
        <span>
          <b class="lucky_number">{{ nonePrizeUsers.length }}</b>
          人参与
        </span>

        <div class="select_box">
          一次抽 {{ prize.luckCount }} 人
        </div>
      </div>
      <div id="open" ref="open" class="btn_circle btn_start" v-if="noLuck && step === 1" @click="start">开始</div>
      <div id="open" class="btn_circle btn_start" v-if="!noLuck && step === 1" @click="reStart">重置</div>
      <div style="position: absolute; bottom: 30px;">
        {{ screenCode }}
      </div>
    </div>
    <div class="right lucky_people_list">
      <div class="lucky_people_title">中奖名单</div>
      <div class="lpl_list clearfix">
        <div class="lpl_userInfo" style="" v-for="(item, index) in prizeUsers" :key="index">
          <img class="lpl_userImage"
            :src="item.avatar || 'https://thirdwx.qlogo.cn/mmopen/vi_32/POgEwh4mIHO4nibH0KlMECNjjGxQUq24ZEaGT4poC6icRiccVGKSyXwibcPq4BWmiaIGuG1icwxaQX6grC9VemZoJ8rg/132'"
            style="">
          <p class="lpl_userName" style="">{{ item.name }}</p>
        </div>
      </div>
      <div class="lpl_list clearfix none">

      </div>
      <div class="lpl_list clearfix none">

      </div>
    </div>

    <a-modal :width="1000" v-model:visible="showLogin" :confirm-loading="loading" :footer="null">
      <IndexLogin></IndexLogin>
    </a-modal>
    <a-modal :width="1000" v-model:visible="showReset" :confirm-loading="loading" :footer="null">
      <IndexReset></IndexReset>
    </a-modal>
  </div>
  <audio src="https://juhui.teampoint.cn/static/choujiang.mp3" ref="audio" id="audio" controls hidden="true" loop @ended="play"></audio>
</template>

<script>
import { createVNode } from 'vue'
import {
  ExclamationCircleOutlined
} from '@ant-design/icons-vue'
import * as sceneScreenApi from '@/api/scene/screen'
import * as sceneLuckApi from '@/api/scene/luck'
import * as sceneLuckUserApi from '@/api/scene/luckUser'
import * as activitySignApi from '@/api/activity/activityUserSign'
import { createSocket, sendWSPush } from '@/utils/websokcet.js'
import IndexLogin from './index-login.vue'
import IndexReset from './index-reset.vue'
export default {
  name: 'scree-lucky-index',
  components: {
    IndexLogin,
    IndexReset
  },
  data() {
    return {
      id: 0,
      pageData: {},
      prizeList: [],
      prize: { luckName: '' },
      prizeIndex: 0,
      prizeUsers: [],

      nonePrizeUsers: [],

      leftActive: 'active',
      rightActive: 'active',

      step: 1,
      timer: null,

      noLuck: true,

      locked: false,

      screenCode: '',

      keyHelp: 'F9: 开始;F10：停止;',

      imgWidth: '60px',
      imgHeight: '60px',

      showLogin: false,
      showReset: false,

    }
  },
  created(option) {
    document.addEventListener('keydown', this.handleKeyDown)
    this.id = this.$route.query.id
    this.getScreenInfo()
    this.initConnect()
    
    this.$confirm({
      title: '默认打开声音',
      icon: createVNode(ExclamationCircleOutlined),
      maskClosable: false,
      cancelButtonProps: { style: { display: 'none' } },
      onOk: () => {
        this.play()
        setTimeout(() => {
          this.pause()
        }, 300)
      }
    })
  },
  unmounted() {
    document.removeEventListener('keydown', this.handleKeyDown)
  },
  mounted() {
  },
  methods: {
    //键盘按钮截获
    handleKeyDown(e) {
      if (e.code === 'F9') {
        this.start()
        e.preventDefault() // 取消浏览器原有的操作
      }
      if (e.code === 'F10') {
        this.stop()
        e.preventDefault() // 取消浏览器原有的操作
      }
    },
    initConnect() {
      createSocket("wss://juhui.teampoint.cn/pcscreen")
      // createSocket("ws://192.168.0.9:8084/pcscreen")
      window.addEventListener('onmessageWS', this.getsocketData)

    },
    getsocketData(e) {
      const data = e && e.detail.data
      if (this.isJson(data)) {
        const res = JSON.parse(data)

        if (res.code === 101) {
          this.screenCode = '大屏：' + res.screenCode
          sendWSPush({ code: '100', userId: this.$store.state.user.user.userId, screenCode: this.screenCode, screenId: this.id })
        } else if (res.code === 'F9') {
          this.start()
          // // 获取按钮元素
          // const button = this.$refs.open;
          // button.click({ force: true })
          // return
          // // 创建一个点击事件
          // const event = new Event('click', {
          //   bubbles: true,
          //   cancelable: false,
          // });
          // // 触发点击事件
          // button.dispatchEvent(event);
        } else if (res.code === 'F10') {
          this.stop()
        } else if (res.code === 'F6') {
          this.resetLuckUser()
        } else if (res.code === 'F7') {
          this.setPrize(res.prizeIndex)
        } 
      }
    },
    isJson(data) {
      try {
        JSON.parse(data)
        return true
      } catch {
        return false
      }
    },
    getScreenInfo() {
      const hide = this.$message.loading('请求中..', 0)
      sceneScreenApi.getById(this.id).then(res => {
        hide()
        if (res.code === 0) {
          this.pageData = res.data
          window.document.body.style.backgroundImage = 'url(' + this.pageData.bgPic + ')'
          this.changePrize()
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        hide()
        this.$message.error(e.msg)
      })
    },
    setPrize(index) {
      this.prizeIndex = index
      if (this.prizeIndex === 0) {
        this.leftActive = ''
        this.rightActive = 'active'
      } else if (this.prizeIndex === this.prizeList.length - 1) {
        this.leftActive = 'active'
        this.rightActive = ''
      } else {
        this.leftActive = 'active'
        this.rightActive = 'active'
      }

      this.getPrize()

      sendWSPush({ code: '200', userId: this.$store.state.user.user.userId, screenCode: this.screenCode, prizeIndex: this.prizeIndex })
    },
    changePrize(step) {
      if (step) {
        this.prizeIndex += step
        if (this.prizeIndex <= 0) {
          this.prizeIndex = 0
        }
        if (this.prizeIndex >= this.prizeList.length) {
          this.prizeIndex = this.prizeList.length - 1
        }
      }

      if (this.prizeIndex === 0) {
        this.leftActive = ''
        this.rightActive = 'active'
      } else if (this.prizeIndex === this.prizeList.length - 1) {
        this.leftActive = 'active'
        this.rightActive = ''
      } else {
        this.leftActive = 'active'
        this.rightActive = 'active'
      }

      this.getPrize()

      sendWSPush({ code: '200', userId: this.$store.state.user.user.userId, screenCode: this.screenCode, prizeIndex: this.prizeIndex })
    },
    getPrize() {
      const data = { screenId: this.pageData.screenId, sort: 'sortNumber', order: 'asc' }
      sceneLuckApi.all(data).then(res => {
        if (res.code === 0) {
          this.prizeList = res.data
          this.prize = this.prizeList[this.prizeIndex]
          this.getPrizeUser()
          this.getNonePrizeSignUser()
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },
    getNonePrizeSignUser() {
      clearInterval(this.timer)
      activitySignApi.nonePrizeSignUser({ activityId: this.prize.activityId, screenId: this.prize.screenId, luckId: this.prize.luckId }).then(res => {
        if (res.code === 0) {
          this.nonePrizeUsers = res.data
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },
    getPrizeUser() {
      // 获取获奖用户
      console.log('获取获奖用户')
      sceneLuckUserApi.all({ luckId: this.prize.luckId }).then(res => {
        if (res.code === 0) {
          this.prizeUsers = res.data
          if (!res.data || res.data.length == 0) {
            this.noLuck = true
          } else {
            this.noLuck = false
          }
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },
    createLuckUser() {
      const data = { activityId: this.prize.activityId, luckId: this.prize.luckId, screenId: this.prize.screenId }
      sceneLuckApi.createLuckUser(data).then(res => {
        if (res.code === 0) {
          this.changePrize()
        } else {
          this.$message.error(res.msg)
        }
        this.step = 1
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },
    resetLuckUser() {
      const data = { activityId: this.prize.activityId, luckId: this.prize.luckId, screenId: this.prize.screenId }
      sceneLuckApi.resetLuckUser(data).then(res => {
        if (res.code === 0) {
          this.changePrize()
        } else {
          this.$message.error(res.msg)
        }
        this.step = 1
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },
    getRandomNum(min, max) {
      // 返回[min, max]之间的随机数
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    // 随机数函数
    shuffle(arr) {
      // 当前面元素排好后，最后一个元素位置已被确定，故不需要循环到最后一个
      for (var i = 0; i < arr.length - 1; i++) {
        var temp = arr[i]
        var rnd = i + Math.floor(Math.random() * (arr.length - i))
        arr[i] = arr[rnd]
        arr[rnd] = temp
      }
    },
    play() {
      this.$refs.audio.currentTime = 0;
      this.$refs.audio.play();
    },
    pause() {
      this.$refs.audio.pause();
    },
    start() {
      if (this.noLuck && this.step === 1 && this.nonePrizeUsers && this.nonePrizeUsers.length > 0) {
        this.step = 2
        let timePer = 800
        if (this.nonePrizeUsers.length < 5) {
          this.imgWidth = '180px'
          this.imgHeight = '180px'
          timePer = 500
        } else if (this.nonePrizeUsers.length < 10) {
          this.imgWidth = '180px'
          this.imgHeight = '180px'
          timePer = 600
        } else if (this.nonePrizeUsers.length < 50) {
          this.imgWidth = '150px'
          this.imgHeight = '150px'
        } else if (this.nonePrizeUsers.length < 96) {
          this.imgWidth = '100px'
          this.imgHeight = '100px'
        } else {
          this.imgWidth = '60px'
          this.imgHeight = '60px'
        }
        this.timer = window.setInterval(() => {
          this.shuffle(this.nonePrizeUsers)
        }, timePer)
        this.play()
      }
    },
    stop() {
      if (this.step === 2) {
        clearInterval(this.timer)
        this.createLuckUser()
        this.pause()
      }
    },
    reStart() {
      const that = this
      this.$confirm({
        title: '提示',
        content: '确定要清空该项获奖用户吗?',
        icon: createVNode(ExclamationCircleOutlined),
        maskClosable: true,
        onOk: () => {
          that.resetLuckUser()
        }
      })
    }
  }
}
</script>

<style>
* {
  padding: 0;
  margin: 0;
}

html,
body {
  width: 100%;
  min-height: 100%;
  font-size: 20px;
}

body {
  background: no-repeat;
  background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
}

.clearfix:after {
  clear: both;
  display: table;
  content: '';
}

.left {
  float: left;
}

.right {
  float: right
}

.container {
  position: absolute;
  -webkit-transform: perspective(1000px);
  -moz-transform: perspective(1000px);
  -ms-transform: perspective(1000px);
  -o-transform: perspective(1000px);
  transform: perspective(1000px);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  -o-transform-origin: center center;
  transform-origin: center center;
  width: 100%;
  height: 60%;
  top: 20%;
}

.element,
.js_current_dom {
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -ms-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
  background-color: #fff;
}

.btn_circle {
  height: 140px;
  width: 140px;
  border: 20px solid #eee;
  line-height: 100px;
  font-size: 24px;
  text-align: center;
  border-radius: 50%;
  background-color: #f00;
  cursor: pointer;
  position: absolute;
  right: 50px;
  bottom: 50px;
  z-index: 9;
  color: #fff;
  -webkit-transition: all 0.7s linear;
  -moz-transition: all 0.7s linear;
  -ms-transition: all 0.7s linear;
  -o-transition: all 0.7s linear;
  transition: all 0.7s linear;
}

.btn_circle:hover {
  background-color: darkorange;
  border-color: #fff;
}

.btn_start {
  position: static;
  margin: 25px auto 0 auto;
}

.mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: none;
  z-index: 999;
}

.lucky_icon {
  height: 70%;
  width: 70%;
  border-radius: 50%;
  margin-left: 15%;
}

.lucky_userInfo {
  position: absolute;
  height: 100px;
  width: 100px;
  color: #fff;
  font-size: 24px;
}

.lucky_list {
  width: 75%;
  min-width: 1000px;
  height: 750px;
  padding: 20px;
  padding-top: 30px;
  position: relative;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.lucky_list3 {
  width: 75%;
  min-width: 1000px;
  height: 670px;
  padding: 20px;
  position: relative;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin-top: 10px;
  overflow: hidden;
}

.lpl_userInfo {
  width: 84px;
  height: 100px;
  text-align: center;
  float: left;
  margin-bottom: 10px;
}

.lpl_userImage {
  width: 60px;
  height: 60px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.lpl_userName {
  margin-top: 12px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  /* color: #fff; */

}

.lucky_userName {
  text-align: center;
  margin-top: 12px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lucky_title {
  text-align: center;
  width: 100%;
  font-size: 60px;
  font-weight: bold;
  color: #ffd700;
  height: 200px;
  line-height: 200px;
  background: url('https://form.teampoint.cn/static/topbg.png') no-repeat center center;
}

.lucky_prize {
  width: 34%;
  margin-right: 10px;
  height: 100%;
}

.lucky_people_list {
  width: 58%;
  height: 100%;
}

.flex {
  display: flex;
  flex-direction: row;
}

.lucky_prize_box {
  height: 200px;
  display: flex;
  flex-direction: row;
}

.lucky_prize_direction {
  width: 70px;
  height: 100%;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 60px;
  text-align: center;

}

.lucky_prize_direction.active {
  color: #f22;
  cursor: pointer;
}

button:focus {
  outline: none;
}

.lucky_prize_picture {
  width: 200px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.lucky_prize_show {
  max-width: 100%;
  max-height: 100%;
}

.lucky_people_title {
  width: 100%;
  height: 60px;
  background-color: #f22;
  font-size: 24px;
  color: #fff;
  text-align: center;
  line-height: 60px;
  font-weight: bold;
}

.lucky_setting {
  height: 50px;
  background-color: #f22;
  font-size: 16px;
  color: #fff;
  text-align: left;
  line-height: 50px;
  padding: 0 10px;
  font-weight: bold;
  margin-top: 25px;
}

.select_box {
  float: right;
  margin-top: 1px;
}

.select_lucky_number {
  font-size: 22px;
  border: 1px solid #eee;
  outline: none;
}

.lucky_number {
  font-size: 24px;
}

.lucky_prize_title {
  height: 50px;
  background-color: #f22;
  color: #fff;
  text-align: center;
  line-height: 50px;
  font-weight: bold;
  margin-top: 25px;
}

.lpl_list {
  margin-top: 20px;
  height: calc(100% - 80px);
  overflow: auto;
  box-sizing: border-box;
}

.loader_file {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .6);
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 30px;
}

.none {
  display: none;
}
</style>
